<template>
  <div class="order-base__warp">
    <div class="erp-form-bar bar-sticky" style="top: 0">
      <div
        class="erp-primary__btn"
        @click="showDetail(2)"
        v-if="
          (detailsData.orderStatus === 1 || detailsData.orderStatus === 4) &&
          !detailsData.apAudit &&
          hasPermission('erp:fmArap:saveApAudit')
        "
      >
        录入应付
      </div>
      <div
        class="erp-primary__btn"
        @click="showDetail(3)"
        v-if="
          (detailsData.orderStatus === 1 || detailsData.orderStatus === 4) &&
          (detailsData.apAudit || detailsData.modifyApAudit) &&
          hasPermission('erp:fmArap:auditArap')
        "
      >
        审核
      </div>
      <div class="erp-default__btn" @click="back">返回</div>
    </div>
    <!-- 55等分布局 -->
    <el-row :gutter="0" type="flex" class="op-16">
      <el-col :span="12" style="margin-right: 80px">
        <div class="erp-form__title f16">查看应付项目</div>
        <el-row :gutter="30" type="flex" class="pl-12 flex-warp">
          <el-col :span="12">
            <erp-view
              label="销售合同号"
              :value="detailsData.businessContractNo"
            ></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="客户" :value="detailsData.customerName"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view
              label="合同件数"
              :value="detailsData.packageCountDesc"
            ></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view
              label="合同重量(kg)"
              :value="detailsData.contractWeightSumDisplay"
            ></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view
              label="入库件数"
              :value="detailsData.instoreCountSumDisplay"
            ></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view
              label="入库重量"
              :value="detailsData.instoreWeightSumDisplay"
            ></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view
              label="资金方"
              :value="detailsData.fundingShortName"
            ></erp-view>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <!-- 应付项目（待审核） -->
    <payables-audit
      :data="detailsData.apAudit"
      :baseData="detailsData"
      :orderArapId="orderArapId"
      :version="version"
      @cancel="auditCancel"
      v-if="detailsData.apAudit"
    ></payables-audit>
    <receivablesEdit
      :baseData="detailsData"
      :data="detailsData.modifyApAudit"
      :orderArapId="orderArapId"
      :version="version"
      @cancel="auditCancel"
      v-if="detailsData.modifyApAudit"
    ></receivablesEdit>

    <!-- 应付项目（审核已通过） -->
    <xh-payables-pass
      :baseData="detailsData"
      :data="detailsData.apConfirmVo"
      :orderArapId="orderArapId"
      :version="version"
      :arTradeCurrencyDesc="detailsData.arTradeCurrencyDesc"
      v-if="detailsData.apConfirmVo"
      @cancel="auditCancel"
    ></xh-payables-pass>
    <!--审核页面-->
    <erp-drawer
      :visible="visible"
      @close="closeDialog"
      erp
      class="no-padding fix-top-header"
    >
      <xh-audit-accrued
        :isCope="2"
        :baseData="detailsData"
        :orderArapId="orderArapId"
        :version="version"
        :arTradeCurrencyDesc="detailsData.arTradeCurrencyDesc"
        @cancel="closeDialog"
        v-if="type === 3"
      ></xh-audit-accrued>

      <xh-payablesForm
        :baseData="detailsData"
        :orderArapId="orderArapId"
        :version="version"
        @cancel="closeDialog"
        v-if="type === 2"
      ></xh-payablesForm>
    </erp-drawer>
  </div>
</template>

<script>
import payablesAudit from './components/payablesAudit.vue'; // 应付待审核
import xhPayablesPass from './components/xhPayablesPass.vue';
import receivablesEdit from './components/payablesPassEdit.vue';
import xhAuditAccrued from './components/xhAuditAccrued.vue';
import xhPayablesForm from './components/xhPayablesForm.vue';
import { getRouterParams } from '@/utils/publicMethods';

export default {
  name: 'xhDetailCope',
  components: {
    payablesAudit,
    xhPayablesPass,
    receivablesEdit,
    xhAuditAccrued,
    xhPayablesForm,
  },
  props: {},
  data() {
    return {
      detailsData: {},
      orderArapId: null, // 列表获取id
      version: null, // 列表获取版本
      type: null, // 切换不同显示弹窗
      visible: false, // 侧栏弹出显示
    };
  },
  methods: {
    auditCancel(status) {
      if (status) {
        this.getCollectionData(this.orderArapId);
      }
    },
    closeDialog(status) {
      this.visible = false;
      this.auditCancel(status);
    },
    getCollectionData(id) {
      // 获取列表信息
      this.ajax({
        type: 'POST',
        url: '/malicrm/orderArap/queryOrderArapDetailById',
        data: {
          orderArapId: id,
          arapType: 2,
        },
        success: (res) => {
          if (res.code === 200) {
            this.detailsData = res.result;
            this.version = res.result.version;
          } else {
            this.errorTip(res.message);
          }
        },
      });
    },
    showDetail(type) {
      // 显示不同详情
      this.type = type;
      this.visible = true;
    },
    back() {
      // 返回
      this.$router.push('/erp/financial/accruedCope');
    },
  },
  created() {
    // 所有详情都可参照这个范式取传递数据
    const { id, version } = getRouterParams('xhDetailCope');
    this.orderArapId = id;
    this.version = version;
    this.getCollectionData(id);
  },
};
</script>

<style lang="scss" scoped></style>
